<template>
    <div className="animated">
      <b-card>
        <b-card-header>
          <i class="icon-menu mr-1"></i>Voucher Report
        </b-card-header>
        <b-card-body>
            <b-row class="mb-4">
                <b-col sm="6">
                    <div>
                        <b-form-group
                            class="mb-2"
                            label="Partner"
                            label-for="sp"
                        >
                            <b-form-select
                                id="sp"
                                v-model="filterPartner"
                                :options="optionsPartner"
                                @change="doFilterSelect(filterPartner)">
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col sm="6">
                    <div class="finance-report">
                        <span class="wrapper-icon-report">
                            <b-img alt="Rupiah" :src="require('../../../assets/img/Rupiah.png')" width="24px" height="24px;"></b-img>
                        </span>
                        <div class="px-3">
                            <p class="mb-1">Existing Balance</p>
                            <h5><strong>{{ data.ballance }}</strong></h5>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div v-show="isShow">
                <b-row class="mb-4">
                    <b-col sm="6">
                        <div>
                            <b-form-group
                                class="mb-3"
                                label="Date"
                                label-for="daterange"
                            >
                                <b-input-group>
                                    <b-input-group-prepend is-text><i class="fa fa-calendar"></i></b-input-group-prepend>
                                    <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
                                    <b-input-group-append>
                                        <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col sm="6">
                        <div class="finance-report">
                            <span class="wrapper-icon-report">
                                <b-img alt="Rupiah" :src="require('../../../assets/img/money.png')" width="24px" height="24px;"></b-img>
                            </span>
                            <div class="px-3">
                                <p class="mb-1">Total Settlement</p>
                                <h5><strong>{{ data.settlement }}</strong></h5>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="6">
                        <div class="finance-report">
                            <span class="wrapper-icon-report">
                                <b-img alt="Rupiah" :src="require('../../../assets/img/Commision.png')" width="24px" height="24px;"></b-img>
                            </span>
                            <div class="px-3">
                                <p class="mb-1">Total Commission</p>
                                <h5><strong>{{ data.commission }}</strong></h5>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col sm="6">
                        <div class="wrapper-chart-report">
                            <doughnut-voucher-sent
                                :width="160"
                                :height="160"
                                :voucher-data="chartDataSent"
                                ref="chartVoucherSent"
                            />
                            <div class="ml-5">
                                <div class="mb-4">
                                    <p class="mb-1">Voucher Sent</p>
                                    <h4><strong>{{ data.voucher_sent.total }}</strong></h4>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <div style="width: 8px; height: 8px; background-color: #6E41C6; margin-right: 4px;"></div>
                                    <p>{{ `Delivery Success (${data.voucher_sent.success})` }}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <div style="width: 8px; height: 8px; background-color: #D0308E; margin-right: 4px;"></div>
                                    <p>{{ `Delivery Failed (${data.voucher_sent.failed})` }}</p>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="6">
                        <div class="wrapper-chart-report">
                            <doughnut-voucher-created
                                :width="160"
                                :height="160"
                                :voucher-data="chartDataDelivery"
                                ref="chartVoucherDelivery"
                            />
                            <div class="ml-5">
                                <div class="mb-4">
                                    <p class="mb-1">Delivery Success</p>
                                    <h4><strong>{{ data.voucher_delivery.total }}</strong></h4>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <div style="width: 8px; height: 8px; background-color: #7D479D; margin-right: 4px;"></div>
                                    <p>{{ `Redeem (${data.voucher_delivery.redeem})` }}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <div style="width: 8px; height: 8px; background-color: #418FDD; margin-right: 4px;"></div>
                                    <p>{{ `Not Yet Redeem (${data.voucher_delivery.not_redeem})` }}</p>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card-body>
      </b-card>
    </div>
</template>

<script>
  import axios from 'axios'
  import DoughnutVoucherCreated from '../../charts/DoughnutVoucherCreated'
  import DoughnutVoucherSent from '../../charts/DoughnutVoucherSent'

  export default {
    components: {
        DoughnutVoucherCreated,
        DoughnutVoucherSent
    },
    data() {
      return {
        isShow: false,
        startDate: '',
        endDate: '',
        filterPartner: '',
        optionsPartner: [
          { value: '', text: 'Choose Partner' }
        ],
        data: {
            ballance: 'Rp 0',
            settlement: 'Rp 0',
            commission: 'Rp 0',
            voucher_delivery: {
                total: '0',
                redeem: 0,
                not_redeem: 0
            },
            voucher_sent: {
                total: '0',
                success: 0,
                failed: 0
            }
        },
        chartDataDelivery: {
            labels: ["Not Yet Redeem", "Redeem"],
            datasets: [
                {
                    backgroundColor: ["#418FDD", "#7D479D"],
                    data: [0, 0]
                }
            ]
        },
        chartDataSent: {
            labels: ["Delivery Success", "Delivery Failed"],
            datasets: [
                {
                    backgroundColor: ["#6E41C6", "#D0308E"],
                    data: [0, 0]
                }
            ]
        },
        moreParams: {},
        errors: {
            code: '',
            message: '',
            status: ''
        }
      }
    },
    created() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
        this.fetchPartners()
    },
    methods: {
        async onFetch() {
            let newParams

            if ('start_date' in this.moreParams && 'end_date' in this.moreParams) {
                newParams = `?${Object.keys(this.moreParams).map(key => `${key}=${this.moreParams[key]}`).join('&')}`;
            } else {
                this.endDate = await this.getCurrentDateFormatted()
                this.startDate = await this.getLastWeekDateFormatted()
            
                const payload = {
                    start_date: this.startDate,
                    end_date: this.endDate,
                    ...Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'start_date' && key !== 'end_date'))
                }
                this.moreParams = await payload
                newParams = `?${Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&')}`;
            }

            this.$http.get(`voucher/partner/settlement/report${newParams}`)
                .then(async (result) => {
                    if (!this.isShow) this.isShow = true

                    if (result) {
                        this.chartDataDelivery.datasets[0].data = [result.data.data.voucher_not_redeemed, result.data.data.voucher_redeemed]
                        this.chartDataSent.datasets[0].data = [result.data.data.voucher_sent_success, result.data.data.voucher_sent_failed]
                        this.data = {
                            ballance: await this.handleParsingBalance(result.data.data.existing_balance),
                            settlement: await this.handleParsingBalance(result.data.data.total_settlement),
                            commission: await this.handleParsingBalance(result.data.data.total_commission),
                            voucher_delivery: {
                                total: result.data.data.voucher_sent_success.toLocaleString("id-ID"),
                                redeem: result.data.data.voucher_redeemed.toLocaleString("id-ID"),
                                not_redeem: result.data.data.voucher_not_redeemed.toLocaleString("id-ID")
                            },
                            voucher_sent: {
                                total: result.data.data.voucher_sent.toLocaleString("id-ID"),
                                success: result.data.data.voucher_sent_success.toLocaleString("id-ID"),
                                failed: result.data.data.voucher_sent_failed.toLocaleString("id-ID")
                            }
                        }
                        this.$refs.chartVoucherSent.updateChart();
                        this.$refs.chartVoucherDelivery.updateChart();
                    }
                }).catch((error) => {
                    if (error.response) {
                        this.isLoading = false
                        this.errors.code = error.response.status;
                        this.errors.status = error.response.data.meta.code;
                        this.errors.headers = error.response.headers;
                        
                        if (this.errors.status == 400) {
                            this.handleResetReport()
                            this.errors.message = error.response.data.meta.message;
                            this.$swal.fire(
                                'Failed!',
                                this.errors.message,
                                'error'
                            )
                        }
                    }
                });
        },
        fetchPartners() {
            this.$http.get(`partner-list`)
                .then((result) => {
                    const newPartner = result.data.map((item) => { return { value: item.id, text: item.name } })
                    
                    this.isLoading = false
                    this.optionsPartner = [
                        ...this.optionsPartner,
                        ...newPartner
                    ]
                }).catch((error) => {
                    if (error.response) {
                        this.isLoading = false
                        this.errors.code = error.response.status;
                        this.errors.status = error.response.data.meta.code;
                        this.errors.headers = error.response.headers;
                    }
                })
        },
        doFilterSelect(value) {
            this.$events.$emit('select-set', value)
        },
        onFilterSelectSet(value) {
            const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'partner'))
            this.moreParams = value ? {
                ...newParams,
                'partner_id': value
            } : newParams;

            if (value) {
                this.onFetch()
            } else {
                this.handleResetReport()
            }
        },
        doDateFilter(value){
            this.startDate = value.startDate;
            this.endDate = value.endDate;
            this.$events.fire('date-set', [this.startDate, this.endDate])
        },
        onDateSet()  {
            const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_date') && (key != 'end_date')))
            this.page = 1;
            this.moreParams = {
                ...newParams,
                'start_date': this.startDate,
                'end_date': this.endDate
            }
            this.onFetch();
        },
        resetDateFilter() {
            this.startDate = ''
            this.endDate = ''
            this.$events.$emit('date-filter-reset')
        },
        onDateFilterReset() {
            this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_date') && (key !== 'end_date')))
            this.onFetch();
        },
        handleResetReport() {
            this.isShow = false
            this.data = {
                ballance: 'Rp 0',
                settlement: 'Rp 0',
                commission: 'Rp 0',
                voucher_delivery: {
                    total: '0',
                    redeem: 0,
                    not_redeem: 0
                },
                voucher_sent: {
                    total: '0',
                    success: 0,
                    failed: 0
                }
            }
            this.moreParams = {}
            this.startDate = ''
            this.endDate = ''
        },
        handleParsingBalance(value) {
            return value.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        },
        getCurrentDateFormatted() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getLastWeekDateFormatted() {
            const currentDate = new Date();
            const lastWeekDate = new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000);
            const year = lastWeekDate.getFullYear();
            const month = String(lastWeekDate.getMonth() + 1).padStart(2, '0');
            const day = String(lastWeekDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    },
    mounted() {
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('select-set', eventData => this.onFilterSelectSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>

<style>
    .finance-report {
        display: flex;
        align-items: center;
        justify-content: start;
        border: 1px solid #E4E7EA;
        border-radius: 4px;
        overflow: hidden;
    }

    .wrapper-icon-report {
        width: 56px;
        height: 56px;
        background: #4DBD74;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wrapper-chart-report {
        padding: 24px 48px;
        border: 1px solid #E4E7EA;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .wrapper-chart-report p {
        font-size: 12px;
        font-weight: 500;
        color: #606060;
    }
</style>
