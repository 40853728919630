<script>
import { Doughnut, mixins } from "vue-chartjs";

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveData],
  props: {
    voucherData: {
      type: Object,
      default: () => [0, 0]
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        width: 160,
        height: 160,
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    updateChart() {
      this.$data._chart.destroy();
      this.renderChart(this.voucherData, this.chartOptions);
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    voucherData: function (newValue) {
      this.updateChart();
    }
  },
  mounted() {
    this.renderChart(this.voucherData, this.chartOptions);
  }
};
</script>